 <template>
  <div class="footer_wrap">
    <!-- Footer Start -->
    <div class="flex-grow-1"></div>
    <div class="app-footer">
      <div class="row">
        <div class="col-md-9">
          <p><strong>Gull - Vuejs Admin Dashboard Template</strong></p>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero
            quis beatae officia saepe perferendis voluptatum minima eveniet
            voluptates dolorum, temporibus nisi maxime nesciunt totam
            repudiandae commodi sequi dolor quibusdam sunt.
          </p>
        </div>
      </div>
      <div
        class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <div class="d-flex align-items-center">
          <img class="logo" src="@/assets/images/logo.png" alt="" />
          <div>
            <div>
              <p class="m-0">&copy; 2019 UI-Lib</p>
              <p class="m-0">All rights reserved</p>
            </div>
          </div>
          <span class="flex-grow-1"></span>
          <a
            class="btn btn-primary text-white btn-rounded"
            href="https://themeforest.net/item/gull-vuejs-admin-dashboard-template/24497287?ref=ui-lib"
            target="_blank"
            >Buy Gull Vue</a
          >
        </div>
      </div>
      <!-- fotter end -->
    </div>
  </div>
</template> 
<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>


<style lang="scss" scoped>
</style> 